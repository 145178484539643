import { getCssText } from '@amedia/brick-tokens';
import { imageStyle } from './style.js';
import type { BrickImageTemplate } from './types';
let cachedCssText: string | null = null;

const withHttps = (url) =>
  !/^(https?:\/\/|https?%3A%2F%2F)/i.test(url) ? `https:${url}` : url;

export function brickImageSrcset({
  srcsizes,
  url,
  graphicsHost = 'g.acdn.no',
  encodeUri = true,
}) {
  if (!url) {
    return;
  }

  const defaultSizes = [480, 680, 880, 1080, 1280];
  const sizeslist =
    srcsizes.split(',').map((item) => item.trim()) || defaultSizes;

  const srcset = sizeslist
    .map((size) => {
      let resizedUrl = `https://${graphicsHost}/api/reflex/v1/image/resize/${size}`;

      if (encodeUri) {
        resizedUrl = `${resizedUrl}/${encodeURIComponent(
          withHttps(url.trim())
        )}`;
      } else {
        resizedUrl = `${resizedUrl}/${withHttps(url.trim())}`;
      }
      const sizeSplit = size.split('_');
      return `${resizedUrl} ${sizeSplit[0]}w`;
    })
    .join(', ');
  return srcset;
}

export function brickImageSrc({
  srcsize,
  url,
  graphicsHost,
  encodeUri = true,
}) {
  const trimmedUrl = url.trim();
  const resizedUrl = `https://${graphicsHost}/api/reflex/v1/image/resize/${srcsize}`;

  if (encodeUri) {
    return `${resizedUrl}/${encodeURIComponent(trimmedUrl)}`;
  } else {
    return `${resizedUrl}/${trimmedUrl}`;
  }
}

const validateString = (value) => {
  if (typeof value === 'string' && value === 'undefined') {
    return null;
  }
  return value;
};

const buildAttributes = (data: BrickImageTemplate): string => {
  const brickImageAttributes: string[] = [];

  if (data.dataSrc) {
    brickImageAttributes.push(`data-src="${data.dataSrc}"`);
  }
  if (data.dataSrcsize) {
    brickImageAttributes.push(`data-srcsize="${data.dataSrcsize}"`);
  }
  if (data.dataSizes) {
    brickImageAttributes.push(`data-sizes="${data.dataSizes}"`);
  }
  if (data.dataSrcset) {
    brickImageAttributes.push(`data-srcset="${data.dataSrcset}"`);
  }
  if (data.dataWidth && data.dataWidth !== 'undefined') {
    brickImageAttributes.push(`data-width="${data.dataWidth}"`);
  }
  if (data.dataHeight && data.dataHeight !== 'undefined') {
    brickImageAttributes.push(`data-height="${data.dataHeight}"`);
  }
  if (data.dataAltText) {
    brickImageAttributes.push(`data-alt-text="${data.dataAltText}"`);
  } else {
    brickImageAttributes.push(`data-alt-text=""`);
  }
  if (data.dataFetchPriority) {
    brickImageAttributes.push(
      `data-fetch-priority="${data.dataFetchPriority}"`
    );
  }
  if (data.dataLoading) {
    brickImageAttributes.push(`data-loading="${data.dataLoading}"`);
  }
  if (data.dataEncodeUri) {
    brickImageAttributes.push(`data-encode-uri="${data.dataEncodeUri}"`);
  }

  return brickImageAttributes.join(' ');
};

export function brickImageTemplate(data: BrickImageTemplate) {
  const {
    dataSrc = '',
    dataSrcsize,
    dataSrcset = '',
    dataAltText = '',
    dataSizes,
    dataFetchPriority = 'auto',
    dataLoading = 'auto',
    dataWidth = '',
    dataHeight = '',
    graphicsHost = 'g.acdn.no',
    dataEncodeUri = 'true',
  } = data;
  let srcset = '';

  if (dataSrcset) {
    srcset = dataSrcset;
  }
  const encodeUri = dataEncodeUri === 'true';

  if (!dataSrcset && dataSrc) {
    srcset = brickImageSrcset({
      url: dataSrc,
      srcsizes: '280,480,680,880,1080,1280,1680',
      graphicsHost,
      encodeUri,
    });
  }

  const imgsrc = dataSrc
    ? `${brickImageSrc({
        srcsize: dataSrcsize || 480,
        url: dataSrc,
        graphicsHost,
        encodeUri,
      })}`
    : '';

  const ratio = `${validateString(dataWidth)} / ${validateString(dataHeight)}`;
  const ratioVar =
    validateString(dataWidth) && validateString(dataHeight)
      ? `--brick-image-ratio: ${ratio};`
      : '';

  const markup = `<img 
  width="${validateString(dataWidth) || ''}"
  height="${validateString(dataHeight || '')}"
  class="${imageStyle}" 
  fetchpriority="${dataFetchPriority}"
  sizes="${validateString(dataSizes) || ''}" 
  loading="${dataLoading}" 
  srcset="${srcset}"
  src="${imgsrc}" 
  alt="${dataAltText}" 
  style="${ratioVar}">`;

  if (data.isRendered) {
    const attributes = buildAttributes(data);
    return `<brick-image-v${data.version} ${attributes} is-rendered>${markup}</brick-image-v${data.version}>`;
  }
  return markup;
}

export function renderBrickImage(data: BrickImageTemplate) {
  data.isRendered = true;
  data.version = '3';
  return brickImageTemplate(data);
}

export function cssText() {
  if (cachedCssText === null) {
    imageStyle();
    cachedCssText = getCssText(); // Cache the result
  }
  return cachedCssText;
}
